<template>
  <div class="view view--notifications">
    <div v-if="notifications.length">
      <section class="section pt-header background-turquoise">
        <div
          v-for="(date, index) in notifications"
          :key="index">
          <header class="notifications__header background-turquoise">
            <div class="container">
              <p class="notifications__title">
                <i class="material-icons">date_range</i> {{ date.title }}
              </p>
            </div>
          </header>

          <div class="notifications__content container">
            <div class="notifications__list">
              <div
                class="notification mb-5 last"
                v-for="(notification, i) in date.notifications"
                :key="i">
                <div @click="markAsRead(notification)">
                  <div class="notification__header">
                    <div class="notification__icon">
                      <i class="material-icons">{{ notification.icon }}</i>
                    </div>

                    <h3 class="notification__title">{{ notification.title }}</h3>
                  </div>

                  <div class="notification__content text">
                    {{ notification.text }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div
      v-else
      class="empty text-center px-4 pb-6 pt-header">
      <div class="py-8 px-4">
        <i class="material-icons mb-4">notifications</i>
        <h3 class="title title--s">
          Todas las notificaciones han sido leídas
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import { readNotification } from '@/services/notifications';

import { mapState } from 'vuex';

export default {
  name: 'notifications',
  data() {
    return {
      loading: false,
    };
  },
  created() {
    this.$store.commit('set_title', 'Notificaciones');
  },
  mounted() {
    window.vueComponents.nav = this;
    this.$store.dispatch('notifications', {});
  },
  computed: {
    ...mapState(['notifications']),
  },
  methods: {
    markAsRead(notification) {
      this.loading = true;

      const data = {
        id: notification.id,
        _method: 'PATCH',
      };

      this.readNotificationApi(this.parseDataBeforeSend(data))
        .then((resp) => {
          this.$store.commit('set_notifications', resp);
          this.goToNotify(notification);
        })
        .then(() => {
          this.loading = false;
        });
    },
    goToNotify(notification) {
      this.$router.push({
        name: notification.meta.name,
        params: {
          slug: notification.params.slug,
        },
        query: {
          period: notification.params.period,
          type: notification.params.type,
        },
      });
    },
    parseDataBeforeSend(data) {
      const formData = new FormData();
      Object.keys(data).forEach(key => formData.append(key, data[key]));
      return formData;
    },
    async readNotificationApi(data) {
      const response = await readNotification(data);

      return response.data;
    },
  },
};
</script>
