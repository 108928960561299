<template>
  <div class="auth">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'auth',
};
</script>

<style>

</style>
