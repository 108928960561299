var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"signup"},[_c('auth-header'),_c('div',{staticClass:"pt-6 pb-5 container"},[_c('page-nav',{staticClass:"mb-5",attrs:{"data":_vm.menu,"color":"auth"}}),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{ref:"form",staticClass:"form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('fieldset',{staticClass:"form__item"},[_c('div',{staticClass:"form__fields"},[_c('ValidationProvider',{staticClass:"form-group form-group--auth",attrs:{"name":"usuario","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"form-label form-label--auth",attrs:{"for":"rut"}},[_vm._v("Usuario")]),_c('div',{class:['form-wrapper', classes]},[_c('i',{staticClass:"form-icon material-icons"},[_vm._v("account_circle")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.rut),expression:"data.rut"}],class:['form-control form-control--auth', classes],attrs:{"type":"text","id":"rut","name":"rut","tabindex":"1"},domProps:{"value":(_vm.data.rut)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "rut", $event.target.value)}}})]),(errors.length)?_c('small',{staticClass:"form-helper"},[_c('i',{staticClass:"material-icons"},[_vm._v("info")]),_vm._v(" "+_vm._s(errors[0])+" ")]):_c('small',{staticClass:"form-helper"},[_vm._v(" Ingresa tu rut sin puntos ni guion ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group form-group--auth",attrs:{"name":"contraseña","rules":"required|min:6|confirmed:confirmation","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"form-label form-label--auth",attrs:{"for":"password"}},[_vm._v("Contraseña")]),_c('div',{class:['form-wrapper', classes]},[_c('i',{staticClass:"form-icon material-icons"},[_vm._v("lock_outline")]),(_vm.hidePassword)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.password),expression:"data.password"}],class:['form-control form-control--auth', classes],attrs:{"type":"password","id":"password","name":"password","data-vv-name":"contraseña","tabindex":"2"},domProps:{"value":(_vm.data.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "password", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.password),expression:"data.password"}],class:['form-control form-control--auth', classes],attrs:{"type":"text","id":"password","name":"password","data-vv-name":"contraseña","tabindex":"2"},domProps:{"value":(_vm.data.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "password", $event.target.value)}}}),_c('button',{staticClass:"form-button button",attrs:{"type":"button"},on:{"click":function($event){_vm.hidePassword = !_vm.hidePassword}}},[_c('i',{staticClass:"button__icon material-icons",domProps:{"textContent":_vm._s(_vm.hidePassword ? 'visibility' : 'visibility_off')}})])]),(errors.length)?_c('small',{staticClass:"form-helper"},[_c('i',{staticClass:"material-icons"},[_vm._v("info")]),_vm._v(" "+_vm._s(errors[0])+" ")]):_c('small',{staticClass:"form-helper"},[_vm._v(" Tu contraseña debe tener mínimo 6 caracteres ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group form-group--auth",attrs:{"name":"contraseña","rules":"required","tag":"div","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"form-label form-label--auth",attrs:{"for":"confirm_password"}},[_vm._v(" Repetir contraseña ")]),_c('div',{class:['form-wrapper', classes]},[_c('i',{staticClass:"form-icon material-icons"},[_vm._v("lock_outline")]),(_vm.hideConfirmPassword)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmation),expression:"confirmation"}],class:['form-control form-control--auth', classes],attrs:{"type":"password","id":"confirm_password","name":"confirm_password","data-vv-name":"contraseña","tabindex":"3 "},domProps:{"value":(_vm.confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmation=$event.target.value}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmation),expression:"confirmation"}],class:['form-control form-control--auth', classes],attrs:{"type":"text","id":"password","name":"confirm_password","data-vv-name":"contraseña","tabindex":"3 "},domProps:{"value":(_vm.confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmation=$event.target.value}}}),_c('button',{staticClass:"form-button button",attrs:{"type":"button"},on:{"click":function($event){_vm.hideConfirmPassword = !_vm.hideConfirmPassword}}},[_c('i',{staticClass:"button__icon material-icons",domProps:{"textContent":_vm._s(_vm.hideConfirmPassword ? 'visibility' : 'visibility_off')}})])]),(errors.length)?_c('small',{staticClass:"form-helper"},[_c('i',{staticClass:"material-icons"},[_vm._v("info")]),_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form__submit mt-4"},[_c('infinite-button',{staticClass:"button--submit",attrs:{"disabled":_vm.loading,"label":!_vm.loading ? 'Comienza aquí' : 'Registrando',"loading":_vm.loading,"icon":null,"theme":"white","type":"submit","tabindex":"4"}})],1)])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }