var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"signup-data signup-data--complete"},[_c('div',{staticClass:"pt-8 pb-7 container"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"title title--xl my-2"},[_vm._v(_vm._s(_vm.user.name)+",")]),_c('p',{staticClass:"title title--s"},[_vm._v("Completa tus datos de contacto")])]),_vm._m(0),_c('ValidationObserver',{attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{ref:"form",staticClass:"form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('fieldset',{staticClass:"form__item"},[_c('div',{staticClass:"form__fields signup-data__separator"},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"correo","rules":"required|email|max:100|integra","tag":"div","vid":_vm.data.email},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"email"}},[_c('i',{staticClass:"material-icons"},[_vm._v("mail_outline")]),_vm._v(" Correo personal: ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.email),expression:"data.email"}],class:['form-control', classes],attrs:{"type":"email","id":"email","name":"email","placeholder":"mailejemplo@mail.com","data-vv-name":"correo personal"},domProps:{"value":(_vm.data.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "email", $event.target.value)}}}),(errors.length)?_c('small',{staticClass:"form-helper"},[_c('i',{staticClass:"material-icons"},[_vm._v("info")]),_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"correo","rules":("required|email|max:100|integra|confirmed:" + (_vm.data.email)),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"confirm_email"}},[_c('i',{staticClass:"material-icons"},[_vm._v("mail_outline")]),_vm._v(" Confirmar correo personal: ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmation),expression:"confirmation"}],class:['form-control', classes],attrs:{"type":"email","id":"confirm_email","name":"confirm_email","placeholder":"mailejemplo@mail.com","data-vv-name":"correo personal"},domProps:{"value":(_vm.confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmation=$event.target.value}}}),(errors.length)?_c('small',{staticClass:"form-helper"},[_c('i',{staticClass:"material-icons"},[_vm._v("info")]),_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"celular","rules":"required|numeric|max:8","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"phone"}},[_c('i',{staticClass:"material-icons"},[_vm._v("phone_iphone")]),_vm._v(" Celular: ")]),_c('div',{class:['form-wrapper', classes]},[_c('span',{staticClass:"form-prefix"},[_vm._v("+56 9")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.phone),expression:"data.phone"}],class:['form-control form-control--prefix', classes],attrs:{"type":"number","id":"phone","name":"phone","placeholder":"1234 5678","data-vv-name":"celular"},domProps:{"value":(_vm.data.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "phone", $event.target.value)}}})]),(errors.length)?_c('small',{staticClass:"form-helper"},[_c('i',{staticClass:"material-icons"},[_vm._v("info")]),_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"text"},[_c('p',[_vm._v(_vm._s(_vm.helpText))])]),_c('div',{staticClass:"form__submit"},[_c('infinite-button',{class:{ 'button--finish' : _vm.loading },attrs:{"disabled":_vm.loading,"label":!_vm.loading ? 'Comenzar' : 'Comenzando...',"loading":_vm.loading,"icon":null,"type":"submit"}})],1)])])]}}])})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"progress-line mt-7 mb-6"},[_c('div',{staticClass:"progress-line__item progress-line__item--active"}),_c('div',{staticClass:"progress-line__item progress-line__item--active"})])}]

export { render, staticRenderFns }