/* eslint-disable max-len */
<template>
  <div class="background-shape">
    <div class="background-shape__inner">
      <svg viewBox="0 0 427 432">
        <defs>
          <path id="a" d="M0 0h427v432H0z"/>
        </defs>
        <g fill="none" fill-rule="evenodd">
          <mask id="b" fill="#fff">
            <use xlink:href="#a"/>
          </mask>
          <path
            :fill="color"
            d="M279.602485 423.683129c19.131988-7.4877 36.286877-20.275589
            51.702899-34.579409 61.530503-57.028576 97.875267-141.569643
            95.593144-222.326454-.83715-29.566244-6.877541-139.2467405-22.781159-162.63500526C389.689896-17.0779985 367.97076-31.9252134
            344.710912-42.5423097 263.099949-79.7662392 157.534483-65.3175137 80.100363-6.32435697
            54.4047658 13.2629316 31.4410373 37.7519189 17.216172 66.8305014-12.1686727
            126.946674-.19921334 279.023103 25.1646411 333.435024c27.2207237
            58.402389 79.4713329 70.730482 140.5743539 87.67325 36.534014 10.135008
            76.576932 17.160126 113.86349 2.574855z"
            mask="url(#b)" />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#3066B1',
    },
  },
};
</script>
