/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import Vue from 'vue';
import VuePageTransition from 'vue-page-transition';
import BootstrapVue from 'bootstrap-vue';
import {
  ValidationProvider, ValidationObserver, extend, localize,
} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import vRut from 'v-rut';
import { validate as rutValidation } from 'v-rut/dist/VeeRutValidator';
import es from 'vee-validate/dist/locale/es.json';
import Toasted from 'vue-toasted';
import VueYoutube from 'vue-youtube';
import { APIHelper } from '@/services/axios';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import '@/assets/scss/app.scss';

localize('es', es);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueYoutube);
Vue.use(vRut);
Vue.use(Toasted, {});
Vue.use(VuePageTransition);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// eslint-disable-next-line import/no-unresolved
extend('rut', {
  validate: rutValidation,
  message: 'El RUT es inválido',
});

extend('integra', {
  validate: value => !value.includes('integra.cl'),
  message: 'El campo {_field_} no puede ser un correo institucional',
});

for (const rule in rules) {
  extend(rule, {
    ...rules[rule],
    message: es.messages[rule],
  });
}

window.vueComponents = {};

new Vue({
  router,
  store,
  beforeCreate() {
    if (this.$store.state.token) {
      APIHelper.setToken(this.$store.state.token);
    }
  },
  render: h => h(App),
}).$mount('#app');

/* eslint-disable no-console, func-names, prefer-arrow-callback, space-before-function-paren, quotes */
document.addEventListener('deviceready', function() {
  // Configurar el color de la barra de estado, si está disponible
  if (typeof StatusBar !== 'undefined') {
    StatusBar.backgroundColorByName('white');
    StatusBar.styleDefault();
  }

  // Configurar el nivel de logueo de OneSignal para ver los logs en Logcat
  window.plugins.OneSignal.setLogLevel(window.plugins.OneSignal.LOG_LEVEL.VERBOSE, window.plugins.OneSignal.LOG_LEVEL.NONE);

  // Inicializar OneSignal con la clave de la aplicación
  window.plugins.OneSignal
    .startInit("95d4157b-9813-4684-a5fe-bbc956fff034")
    .handleNotificationOpened(function(jsonData) {
      console.log('Notification opened:', jsonData);
    })
    .endInit();

  // Verificar el estado de la suscripción
  window.plugins.OneSignal.getPermissionSubscriptionState(function(status) {
    console.log('Subscription Status:', status);
  });
}, false);
/* eslint-enable no-console, func-names, prefer-arrow-callback, space-before-function-paren, quotes */

