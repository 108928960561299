var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view view--home"},[_c('section',{staticClass:"section section--light pt-9"},[_c('div',{staticClass:"container pt-7"},[(_vm.showAlert)?_c('div',{staticClass:"mb-6"},[_c('transition',{attrs:{"appear":"","name":"fade"}},[_c('banner-alert',{attrs:{"button":{
              label: 'Aportar',
              to: { name: 'covid19' }
            },"closable":false,"text":_vm.alert_message},on:{"close":_vm.hideAlert}})],1)],1):_vm._e(),_c('heading',{attrs:{"data":_vm.today.heading}}),(_vm.today.data.length)?_c('div',[_c('div',{staticClass:"mt-5"},[_vm._l((_vm.today.data),function(button){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPublic(button)),expression:"isPublic(button)"}],key:button.title,staticClass:"mb-5 last"},[_c('section-button',{attrs:{"data":button}})],1)}),(_vm.showSurveys)?_c('div',_vm._l((_vm.surveys),function(button){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPublic(button)),expression:"isPublic(button)"}],key:button.title,staticClass:"mb-5 last"},[_c('section-button',{attrs:{"data":{
                  class: button.class,
                  icon: button.icon,
                  slug: ("encuestas/" + (button.slug) + "?color=" + (button.class)),
                  title: button.title_button
                }}})],1)}),0):_vm._e(),(_vm.user.is_parent)?_c('div',{staticClass:"mb-5 last"},[_c('section-button',{attrs:{"data":_vm.material}})],1):_vm._e()],2)]):_c('div',[_c('div',{staticClass:"mt-5"},_vm._l((3),function(item,index){return _c('div',{key:index,staticClass:"mb-5 last"},[_c('section-button',{attrs:{"loading":true}})],1)}),0)])],1)]),(_vm.posts.data.length)?_c('section',{staticClass:"section section--lighter"},[_c('div',{staticClass:"container"},[_c('heading',{attrs:{"data":_vm.releases.heading,"theme":"red"}}),(_vm.posts.data.length)?_c('div',[_c('div',{staticClass:"mt-5"},_vm._l((_vm.releases.data),function(release){return _c('div',{key:release.id,staticClass:"mb-5 last"},[_c('post-card',{attrs:{"data":release,"url":("/comunicados/" + (release.slug)),"theme":"red"}})],1)}),0)]):_c('div',[_c('div',{staticClass:"mt-5"},_vm._l((_vm.defaultLoadingItems),function(item,index){return _c('div',{key:index,staticClass:"mb-5 last"},[_c('post-card',{attrs:{"loading":true}})],1)}),0)])],1)]):_vm._e(),(_vm.posts.data.length)?_c('section',{staticClass:"section section--white pb-7"},[_c('div',{staticClass:"container"},[_c('heading',{attrs:{"data":_vm.posts.heading,"theme":"blue"}}),(_vm.posts.data.length)?_c('div',[_c('div',{staticClass:"mt-5"},_vm._l((_vm.posts.data),function(post){return _c('div',{key:post.id,staticClass:"mb-5 last"},[_c('post-card',{attrs:{"data":post,"url":("/noticias/" + (post.slug)),"theme":"blue"}})],1)}),0)]):_c('div',[_c('div',{staticClass:"mt-5"},_vm._l((_vm.defaultLoadingItems),function(item,index){return _c('div',{key:index,staticClass:"mb-5 last"},[_c('post-card',{attrs:{"loading":true}})],1)}),0)])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }